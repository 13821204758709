
export interface IMainInformation {
    id: number,
     
    portable1: string,
    logo1: string,
    logo1_Url: string,
    email1: string,
    horaire1: string,
    lienFacebook: string,
    lienTwitter: string,
    lienInstagram: string,
    lienLinkedIn: string,
    localisation: string,
    titreBienvenue: string,
    titreCentre: string,
    descriptionBienvenue: string,

}

export interface IIMage4Carousel {
    id: number,
     
    image: string, 
    image_Url: string, 
    titrePrincipal: string, 
    titreSecondaire: string
}

export interface ISlideImage {
    id: number,
     
    image: string, 
    image_Url: string,   
    description: string  
}

export interface IValueDefintion {
    id: number,

    titreGlobal: string,
    titreListe: string,
    titreQuestion: string,

    image: string,
    image_Url: string
}

export interface IValueSpa {
    id: number,

    principale: string,
    sacondaire: string
}


export interface ISpecialOfferDefintion {
    id: number,

    titreGlobal: string,
    titre: string,    
}

export interface ISpecialOffer {
    id: number,

    nom: string,
    desc1: string,    
    desc2: string,    
    desc3: string,    
    desc4: string,    
    desc5: string,  
    
    image: string,
    image_Url: string
}


export interface ISpecialityDefinition {
    id: number,

    titreGlobal: string,
    titre: string,   
}

export interface ISpeciality {
    id: number,

    nom: string,
    description: string,   
    image: string,
    image_Url: string 
}

export interface IReservation {
    id: number,

    titreGlobal: string,
    titre: string,    
    messageAccueil: string,    
    messageReservation: string,    
    messageReservationOnline: string,    

    image: string,
    image_Url: string 
}

export interface IGaleryPhoto {
    id: number,
  

    image: string,
    image_Url: string 
}


export const defaultMainInformation : IMainInformation = {
    id: 0,
     
    portable1: '',
    logo1: '',
    logo1_Url: '',
    email1: '',
    horaire1: '',
    lienFacebook: '',
    lienTwitter: '',
    lienInstagram: '',
    lienLinkedIn: '',
    localisation: '',
    titreBienvenue: '',
    titreCentre: '',
    descriptionBienvenue: '',
}

export const defaultIMage4Carousel : IIMage4Carousel = {
    id: 0,
     
    image: '', 
    image_Url: '', 
    titrePrincipal: '', 
    titreSecondaire: ''
}

export const defaultValueDefintion : IValueDefintion = {
    id: 0,

    titreGlobal: '',
    titreListe: '',
    titreQuestion: '',

    image: '',
    image_Url: ''
}

export const defaultSpecialOfferDefintion :  ISpecialOfferDefintion = {
    id: 0,

    titreGlobal: '',
    titre: '',    
}

export const defaultSpecialityDefinition : ISpecialityDefinition = {
    id: 0,

    titreGlobal: '',
    titre: '',  
}

export const defaultReservation : IReservation = {
    id: 0,

    titreGlobal: '',
    titre: '',     
    messageAccueil: '',    
    messageReservation: '',    
    messageReservationOnline: '',    

    image: '',
    image_Url: ''  
}